import ListTitle from "./ListTitle"
import { CircularProgress, Stack } from "@mui/material"
import { appAction, fetchWithRetry, modalType } from "./helpers"
import { useEffect, useState } from "react"
import { STRING_PROGRESS_NO_RESULTS } from "./strings";

const secondsWaitingTitleShowsOnDownloads = 60 * 60 * 12;
const secondsDoneTitleShowsOnDownloads = 60 * 60 * 24 * 7;
const secondsStalled = 60 * 60 * 24 * 3;

const updatePending = (dispatch) => {
    return fetchWithRetry(`/api/progress`)
        .then(response => response.json())
        .then(response => {
            const now = Math.floor(Date.now() / 1000);
            const titles = response.result;

            let titlesDownloadingOrStalled = titles.filter(title => (
                // Title is PENDING or DOWNLOADING
                (title.pending_info?.status !== 'WAITING' && title.x_status !== 'HAVE') ||
                // OR Title is WAITING and newish
                (title.pending_info?.status === 'WAITING' && (now - (title.pending_info?.start_ts ?? 0) < secondsWaitingTitleShowsOnDownloads)) ||
                // OR Title is DONE in the last X days
                (title.x_status === 'HAVE' && (now - (title.pending_info?.done_ts ?? 0) < secondsDoneTitleShowsOnDownloads))
            ))

            let titlesStalled = titlesDownloadingOrStalled.filter(title => (
                title.pending_info?.status === 'PENDING' &&
                now - title.pending_info?.last_update_ts > secondsStalled
            )).sort((a, b) => b.x_add_time - a.x_add_time)

            let titlesDownloading = titlesDownloadingOrStalled.filter(title => (
                title.pending_info?.status !== 'PENDING' ||
                now - title.pending_info?.last_update_ts <= secondsStalled
            ))

            const titlesPending = titlesDownloading.filter(t => t.x_status === 'PENDING')
            titlesPending.sort((a, b) => b.x_add_time - a.x_add_time)

            const titlesDone = titlesDownloading.filter(t => t.x_status !== 'PENDING')
            titlesDone.sort((a, b) => (b.x_complete_time ?? b.x_add_time) - (a.x_complete_time ?? a.x_add_time))


            titlesDownloading = [...titlesPending, ...titlesDone]

            const titlesWatching = titles.filter(title => (title.pending_info?.status === 'WAITING'))
            titlesWatching.sort((a, b) => (new Date(a.pending_info?.available_date ?? 'Jan 1, 2099')) - (new Date(b.pending_info?.available_date ?? 'Jan 1, 2099')))

            dispatch({ type: appAction.update, value: { titlesDownloading, titlesWatching, titlesStalled } })
        })
}

export const DownloadsPage = ({ appState, dispatch }) => {
    return (
        <ProgressPage
            titleList={appState.titlesDownloading}
            dispatch={dispatch}
            updateSeconds={60}
        />
    )
}

export const WatchingPage = ({ appState, dispatch }) => {
    return (
        <ProgressPage
            titleList={appState.titlesWatching}
            dispatch={dispatch}
            updateSeconds={60 * 10}
        />
    )
}

export const StalledPage = ({ appState, dispatch }) => {
    return (
        <ProgressPage
            titleList={appState.titlesStalled}
            dispatch={dispatch}
            updateSeconds={60 * 10}
        />
    )
}

const ProgressPage = ({ titleList, dispatch, updateSeconds }) => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        updatePending(dispatch).then(() => setLoading(false));
        const updateInterval = setInterval(() => {
            setLoading(true);
            updatePending(dispatch).then(() => setLoading(false));
        }, updateSeconds * 1000);
        return () => {
            clearInterval(updateInterval);
        }
    }, [dispatch, updateSeconds])
    return (
        <div>
            {titleList === null && loading &&
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                    <CircularProgress />
                </div>
            }
            {titleList !== null && titleList.length === 0 &&
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                    {STRING_PROGRESS_NO_RESULTS}
                </div>
            }
            <Stack direction="column" spacing={2} marginTop={2}>
                {(titleList ?? []).map(title => (
                    <div
                        style={{ cursor: 'pointer' }}
                        key={title.imdb_id}
                        onClick={() => {
                            dispatch({ type: appAction.update, value: { modalItem: title, modalType: modalType.title } })
                        }}
                    >
                        <ListTitle key={title.imdb_id} title={title} />
                    </div>
                ))}
            </Stack>
        </div>
    )
}